import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { GetCollectionsQuery } from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
import { Fireworks } from 'fireworks-js';

@Component({
    selector: 'vsf-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit, OnDestroy {

    collections$: Observable<GetCollectionsQuery['collections']['items']>;
    heroImage: SafeUrl;

    features = [
        { title: "Supreme Quality Crackers", content: "At Rohini Crackers, we prioritize quality above all. Our crackers are made from the finest raw materials, ensuring a superior and safe experience for all our customers." },
        { title: "Innovative & Attractive Designs", content: "We offer an innovative range of crackers that are not only visually appealing but also come in secure and creative packaging. Whether you're looking for traditional or modern designs, we have something for everyone." },
        { title: "Vibrant & Colourful Displays", content: "Add an extra spark to your celebrations with our colourful range of crackers. Designed to produce vivid colors with minimal smoke, our crackers are perfect for a joyful and environmentally conscious festival." },
        { title: "Safety First", content: "Your safety is our priority. Rohini Crackers provides a wide selection of 100% safe crackers, especially curated for children. Our products meet all safety standards, ensuring a worry-free celebration for your family." },
        { title: "Competitive Pricing", content: "We believe in providing value for money. Rohini Crackers offers top-quality fireworks at genuine and affordable prices, making your celebrations not just memorable but also budget-friendly." },
        { title: "Customer Satisfaction Guaranteed", content: "Our dedicated customer care team is here to ensure that your experience with us is nothing short of perfect. We stand by the quality of our products and guarantee full satisfaction with every purchase." },
    ];

    constructor(private dataService: DataService, private changeDetectorRef: ChangeDetectorRef) {
    }

    currentIndex = 0;
    itemsPerView = 2;
    autoScrollInterval: any;
    autoScrollPaused = false;
    
    ngOnInit(): void {
        
        this.collections$ = this.dataService.query<GetCollectionsQuery>(GET_COLLECTIONS, {
            options: { take: 50 },
        }).pipe(map(({collections}) => collections.items));
        this.heroImage = this.getHeroImageUrl();
        
        this.addScrollAnimation();

        this.updateItemsPerView();
        window.addEventListener('resize', this.updateItemsPerView.bind(this));
        this.startAutoScroll();
    }

    ngOnDestroy() {
        this.stopAutoScroll();
        window.removeEventListener('resize', this.updateItemsPerView.bind(this));
      }

      slides = [
        {
          title: "Welcome to Rohini Crackers",
          content: "Illuminate your Diwali with <span class='text-orange-500 font-bold'>Rohini Crackers</span>! We're your trusted partner for premium quality fireworks. With over the years of experience, we bring you the brightest and safest crackers to make your festival truly spectacular.",
          icon: "🎆"
        },
        {
          title: "Unbeatable Diwali Discounts",
          content: "Light up the sky without burning a hole in your pocket! Enjoy <span class='text-red-500 font-bold'>massive discounts up to 80% OFF</span> on our entire range. From sparklers to aerial shots, everything is on sale. Don't miss out on our buy-one-get-one offers on selected items!",
          icon: "💥"
        },
        {
          title: "Convenient Shopping Options",
          content: "Shop your way! Browse our extensive collection on our <span class='text-blue-500 font-bold'>user-friendly website</span> or visit our <span class='text-green-500 font-bold'>physical stores</span> for a hands-on experience. Our knowledgeable staff is ready to assist you in finding the perfect crackers for your celebration.",
          icon: "🏪"
        },
        {
          title: "Widest Range of Fireworks",
          content: "Discover an explosive variety! From classic <span class='text-purple-500 font-bold'>sparklers and chakras</span> to spectacular <span class='text-orange-500 font-bold'>aerial fireworks and rockets</span>. We also offer eco-friendly options and noiseless crackers. Whatever your preference, we have it all to make your Diwali unforgettable!",
          icon: "🎇"
        },
        {
          title: "Safety First",
          content: "Celebrate with peace of mind! All our products are <span class='text-green-500 font-bold'>rigorously safety tested</span> and comply with the highest quality standards. We provide detailed safety instructions with each purchase and offer free safety gear with bulk orders. Your safety is our top priority!",
          icon: "✅"
        },
        {
          title: "Exclusive Diwali Combos",
          content: "Get more bang for your buck with our <span class='text-blue-500 font-bold'>specially curated Diwali combo packs</span>! Perfect for families, housing societies, or corporate events. Our combos offer a balanced mix of various fireworks, ensuring a complete and thrilling Diwali experience at an incredible value.",
          icon: "🎁"
        }
      ];

      get currentTranslation(): number {
        return this.currentIndex * 100;
      }


    updateItemsPerView() {
        if (window.innerWidth >= 1024) {  // lg breakpoint
            this.itemsPerView = 4;
        } else if (window.innerWidth >= 640) {  // sm breakpoint
            this.itemsPerView = 2;
        } else {
            this.itemsPerView = 1;
        }
    }

    next() {
        this.currentIndex = (this.currentIndex + 1) % this.slides.length;
        // if (this.currentIndex < this.slides.length - this.itemsPerView) {
        //     this.currentIndex++;
        // }

        // else if (this.currentIndex === this.slides.length - this.itemsPerView) {
        //     this.currentIndex = 0;
        // }
    }

    prev() {
        this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
        // if (this.currentIndex > 0) {
        //     this.currentIndex--;
        // }
    }

    startAutoScroll() {
        this.autoScrollInterval = setInterval(() => {
          if (!this.autoScrollPaused) {
            this.next();
            this.changeDetectorRef.detectChanges();
          }
        }, 5000);  // Change slide every 3 seconds
    }

    stopAutoScroll() {
        if (this.autoScrollInterval) {
            clearInterval(this.autoScrollInterval);
        }
    }

    pauseAutoScroll() {
        this.autoScrollPaused = true;
    }

    resumeAutoScroll() {
        this.autoScrollPaused = false;
    }

    addScrollAnimation() {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show');
            }
          });
        });
    
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
      }

    private getHeroImageUrl(): string {
        const {apiHost, apiPort} = environment;
        // return `${apiHost}:${apiPort}/assets/preview/d2/pngwing.com-3__preview.png`;
        return `${apiHost}/assets/preview/d2/pngwing.com-3__preview.png`;
    }

}

const GET_COLLECTIONS = gql`
    query GetCollections($options: CollectionListOptions) {
        collections(options: $options) {
            items {
                id
                name
                slug
                parent {
                    id
                    slug
                    name
                }
                featuredAsset {
                    id
                    preview
                }
            }
        }
    }
`;
