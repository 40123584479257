<div class="lg:hidden">
    <button class="border border-primary-100 rounded bg-primary-50 p-2 text-sm text-primary-700" (click)="manuallyExpanded = !manuallyExpanded">
        <fa-icon icon="filter" class="mr-2"></fa-icon>
        Filters
    </button>
</div>
<div class="mb-4 lg:block"
     [ngClass]="filtersExpanded ? 'block' : 'hidden'">
     <label class=""><input type="radio" [checked]="isActiveAll"
        (change)="toggleFacetValueIdInRoute('All')"
        class="h-4 w-4 border-gray-300 rounded text-primary-600 focus:ring-primary-500">
        <span class="ml-3 text-sm text-gray-600">All</span>
    </label>
    <div *ngFor="let facet of facets; trackBy:trackById" class="text-sm"
         [class.enabled]="activeFacetValueIds.length">
        <div class="py-3 font-medium text-gray-900 uppercase">{{ facet.name | titlecase }}</div>
        <ul class="space-y-4">
            <li *ngFor="let value of facet.values; trackBy:trackById" [class.active]="isActive(value.id)">
                <label class=""><input type="radio" [checked]="isActive(value.id)"
                                       (change)="toggleFacetValueIdInRoute(value.id)"
                                       class="h-4 w-4 border-gray-300 rounded text-primary-600 focus:ring-primary-500">
                    <span class="ml-3 text-sm text-gray-600">{{ value.name }}</span>
                </label>
            </li>
        </ul>
    </div>
</div>
  
