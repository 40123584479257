<form [formGroup]="addressForm">
    <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        <div>
            <label
                htmlFor="fullName"
                class="block text-sm font-medium text-gray-700"
            >
                Full name *
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    formControlName="fullName"
                    autoComplete="given-name"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
            </div>
            <div *ngIf="addressForm.get('fullName')?.invalid && addressForm.get('fullName')?.touched" class="text-red-500 text-sm mt-1">
                Full name is required.
              </div>
        </div>

        <!-- <div class="sm:col-span-2">
            <label
                htmlFor="company"
                class="block text-sm font-medium text-gray-700"
            >
                Company
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="company"
                    id="company"
                    formControlName="company"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div> -->

        <div class="sm:col-span-2">
            <label
                htmlFor="streetLine1"
                class="block text-sm font-medium text-gray-700"
            >
                Address *
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="streetLine1"
                    id="streetLine1"
                    formControlName="streetLine1"
                    autoComplete="street-address"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
            <div *ngIf="addressForm.get('streetLine1')?.invalid && addressForm.get('streetLine1')?.touched" class="text-red-500 text-sm mt-1">
                Address is required.
              </div>
        </div>

        <!-- <div class="sm:col-span-2">
            <label
                htmlFor="streetLine2"
                class="block text-sm font-medium text-gray-700"
            >
                Apartment, suite, etc.
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="streetLine2"
                    id="streetLine2"
                    formControlName="streetLine2"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div> -->

        <div>
            <label
                htmlFor="city"
                class="block text-sm font-medium text-gray-700"
            >
                City *
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    formControlName="city"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
            <div *ngIf="addressForm.get('city')?.invalid && addressForm.get('city')?.touched" class="text-red-500 text-sm mt-1">
                City is required.
              </div>
        </div>

        <div>
            <label
                htmlFor="countryCode"
                class="block text-sm font-medium text-gray-700"
            >
                Country *
            </label>
            <div class="mt-1">
                <select
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    formControlName="countryCode" id="countryCode">
                    <option *ngFor="let country of availableCountries" [ngValue]="country.code">
                        {{ country.name }}
                    </option>
                </select>
            </div>
            <div *ngIf="addressForm.get('country')?.invalid && addressForm.get('country')?.touched" class="text-red-500 text-sm mt-1">
                Country is required.
              </div>
        </div>

        <div>
            <label
                htmlFor="province"
                class="block text-sm font-medium text-gray-700"
            >
                State / Province *
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="province"
                    id="province"
                    formControlName="province"
                    autoComplete="address-level1"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
            <div *ngIf="addressForm.get('province')?.invalid && addressForm.get('province')?.touched" class="text-red-500 text-sm mt-1">
                State is required.
              </div>
        </div>

        <div>
            <label
                htmlFor="postalCode"
                class="block text-sm font-medium text-gray-700"
            >
                Postal code *
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    formControlName="postalCode"
                    autoComplete="postal-code"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
            <div *ngIf="addressForm.get('postalCode')?.invalid && addressForm.get('postalCode')?.touched" class="text-red-500 text-sm mt-1">
                Postal Code is required.
              </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="phoneNumber"
                class="block text-sm font-medium text-gray-700"
            >
                Phone *
            </label>
            <div class="mt-1">
                <input
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    formControlName="phoneNumber"
                    autoComplete="tel"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
            <div *ngIf="addressForm.get('phoneNumber')?.invalid && addressForm.get('phoneNumber')?.touched" class="text-red-500 text-sm mt-1">
                Phone is required.
              </div>
        </div>
    </div>
</form>
