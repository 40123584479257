<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="max-w-6xl mx-auto px-4">
    <!-- <ng-container *ngIf="collection$ | async as collection">
        <div class="flex justify-between items-center">
            <h2 class="text-3xl sm:text-5xl font-light tracking-tight text-gray-900 my-8">
                {{collection.name}}
            </h2>
        </div>
        <vsf-collection-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"></vsf-collection-breadcrumbs>

        <ng-container *ngIf="collection.children.length">
            <div class="max-w-2xl mx-auto py-16 sm:py-16 lg:max-w-none border-b mb-16">
                <h2 class="text-2xl font-light text-gray-900">
                    Collections
                </h2>
                <div class="mt-6 grid max-w-xs sm:max-w-none mx-auto sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-4">
                    <vsf-collection-card *ngFor="let child of collection.children"
                                         [collection]="child">
                    </vsf-collection-card>
                </div>
            </div>
        </ng-container>
    </ng-container> -->

    <h2 class="text-3xl sm:text-5xl font-light tracking-tight text-gray-900 my-8" *ngIf="searchTerm$ | async as term">
        Results for <span class="font-medium">"{{ term }}"</span>
    </h2>

    <div class="mt-6 grid sm:grid-cols-5 gap-x-4">
        <vsf-product-list-controls
            class="mb-4"
            [facetValues]="facetValues"
            [activeFacetValueIds]="activeFacetValueIds$ | async"
            [totalResults]="unfilteredTotalItems"></vsf-product-list-controls>
        <div class="sm:col-span-5 lg:col-span-4">
            <div class="grid gap-y-10 gap-x-6 grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                <ng-container *ngIf="(totalResults$ | async) !== null; else placeholders">
                    <vsf-product-card *ngFor="let product of products$ | async; trackBy: trackByProductId"
                                      [product]="product" (prodAsset)="handleProdAsset($event)"></vsf-product-card>
                </ng-container>
                <ng-template #placeholders>
                    <vsf-product-card *ngFor="let product of placeholderProducts"
                                      [product]="product"></vsf-product-card>
                </ng-template>
            </div>
            <div class="load-more flex-fill bg-orange-400 mt-4 p-2 rounded-lg text-center" *ngIf="displayLoadMore$ | async">
                <button class="p-2 text-white w-full btn btn-light btn-lg d-inline-flex"
                        (click)="loadMore()"
                        [disabled]="loading$ | async">
                    Load More
                    <span [class.show]="loading$ | async"
                          class="loading-indicator spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>

    <ng-template #noResults>
        <div class="no-results col-12">
            <p class="h1">No results</p>
            <fa-icon icon="sad-cry" size="10x"></fa-icon>
        </div>
    </ng-template>
</div>


<!-- Media Preview Modal -->

  <div *ngIf="previewVisible" class="fixed inset-0 z-50 bg-black bg-opacity-75 flex items-center justify-center p-4" (click)="closePreview()">
    <div class="relative max-w-full max-h-full bg-white p-4 rounded-lg overflow-hidden" (click)="$event.stopPropagation()">
      <button (click)="closePreview()" class="absolute top-2 right-2 text-white bg-red-500 z-10 px-2 rounded-full">x</button>
      
      <div class="relative flex items-center justify-center">
        <button *ngIf="currentMediaList.length > 1"
          (click)="previousMedia()" 
          class="absolute left-0 transform -translate-y-1/2 p-2 text-white bg-gray-500 rounded-full top-1/2 lg:-left-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        
        <ng-container *ngIf="currentMediaType === 'image'">
          <img [src]="currentMedia" class="h-[478px] mx-auto object-contain" alt="Media Preview" />
        </ng-container>
        <ng-container *ngIf="currentMediaType === 'video'">
          <video controls [src]="currentMedia" class="h-[478px] max-w-full max-h-full mx-auto object-contain"></video>
        </ng-container>
        
        <button *ngIf="currentMediaList.length > 1"
          (click)="nextMedia()" 
          class="absolute right-0 transform -translate-y-1/2 p-2 text-white bg-gray-500 rounded-full top-1/2 lg:-right-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  </div>

