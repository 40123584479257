<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ng-container *ngIf="selectedAsset">
    
     <!-- Display Image -->
     <img *ngIf="selectedType === 'img_selected'" [src]="selectedAsset | assetPreview:540" alt=""
     (click)="openImage(selectedAsset?.id)"
     (load)="onLoad()"
     class="w-full h-full object-center object-cover rounded-lg cursor-zoom-in" />
     
     <!-- Display Video -->
     <video *ngIf="selectedType === 'video_selected'" (click)="openImage(selectedAsset?.id)"
     (load)="onLoad()" controls class="rounded-lg select-none h-full w-full object-cover">
       <source [src]="selectedAsset.source" type="video/mp4" />
       Your browser does not support the video tag.
     </video>
</ng-container>

<div class="lightbox" *ngIf="isOpen">
     <div class="lightbox-content">
       <span class="close" (click)="closeGallery()">&times;</span>
       <button class="prev" (click)="prev()">&#10094;</button>
       <button class="next" (click)="next()">&#10095;</button>
   
       <img *ngIf="selectedType === 'img_selected'" [src]="currentItem?.preview" alt="Large Image">
       <video *ngIf="selectedType === 'video_selected'" [src]="currentItem?.source" controls autoplay></video>
     </div>
   </div>

<div *ngIf="!selectedAsset || !featuredAssetLoaded"
     class="rounded-lg bg-gray-200 animate-pulse aspect-video w-full"></div>

<div class="flex flex-wrap space-x-2 mt-2" *ngIf="1 < assets?.length">
    <div *ngFor="let asset of assets">
         <ng-container [ngSwitch]="getFileType(asset.source)">
    
          <!-- Display Image -->
          <img *ngSwitchCase="'image'" (click)="selectImage(asset.id, 'img_selected')" [src]="asset | assetPreview:'thumb'" alt="Image" class="rounded-lg select-none h-24 w-full object-cover" />
          
          <!-- Display Video -->
          <video *ngSwitchCase="'video'" (click)="selectImage(asset.id, 'video_selected')" controls class="rounded-lg select-none h-24 w-full object-cover">
            <source [src]="asset.source" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
     </ng-container>
         <!-- <img [src]="asset | assetPreview:'thumb'" class="rounded-lg select-none h-24 w-full object-cover"> -->
    </div>
</div>
