import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

import { AssetFragment } from '../../../common/generated-types';

import './types.d';
import { isPlatformBrowser } from '@angular/common';

export type AssetWithDimensions = Pick<AssetFragment, 'id' | 'preview' | 'width' | 'height' | 'source'>;

@Component({
    selector: 'vsf-asset-gallery',
    templateUrl: './asset-gallery.component.html',
    styleUrls: ['./asset-gallery.component.scss'],
})
export class AssetGalleryComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() assets?: AssetWithDimensions[] = [];
    @Input() selectedAssetId: string;
    @ViewChild('mainPreview', {static: false})
    featuredAssetLoaded = false;
    private mainPreview: ElementRef<HTMLImageElement>;

    selectedType?: 'img_selected' | 'video_selected';
    selectedAsset?: AssetWithDimensions;
    private gallery: any;

    isOpen = false;
    currentIndex = 0;

    constructor(@Inject(PLATFORM_ID) private platformId: any) {
    }

    ngOnInit() {
        this.selectImage(this.selectedAssetId);
    }
    
    ngOnChanges() {
        if (this.assets) {
            console.log(this.assets);
            this.initPhotoswipe();
            this.selectImage(this.selectedAssetId);
        }
    }

    ngAfterViewInit() {
        if (this.assets) {
            this.initPhotoswipe();
        }
    }

    onLoad() {
        console.log(`loaded`);
        this.featuredAssetLoaded = true;

    }

    private initPhotoswipe() {
        if (isPlatformBrowser(this.platformId)) {
            const items = this.assets?.map(asset => ({
                src: asset.source,
                msrc: asset.preview + '?preset=medium',
                width: asset.width || 1000,
                height: asset.height || 1000,
            }));
            this.gallery = new PhotoSwipeLightbox({
                dataSource: items,
                pswpModule: () => import('photoswipe'),
                showHideOpacity: true,
            });

            this.gallery.on('contentLoad', (e: any) => {
                const { content, } = e;
          
                if (content && content.element) {
                    if (this.getFileType(content.data.src) === 'video') {
                    const videoElement = document.createElement('video');
                    videoElement.src = content.data.src;
                    videoElement.controls = true;
                    videoElement.style.maxWidth = '100%';
                    videoElement.style.maxHeight = '100%';
            
                    content.element.innerHTML = '';
                    content.element.appendChild(videoElement);
                    } else {
                    const imgElement = document.createElement('img');
                    imgElement.src = content.data.msrc;
                    imgElement.style.maxWidth = '100%';
                    imgElement.style.maxHeight = '100%';
            
                    content.element.innerHTML = '';
                    content.element.appendChild(imgElement);
                    }
                }
              });

            this.gallery.init();
        }
    }

    selectImage(assetId: string, selectedtype: any = 'img_selected') {
        // eslint-disable-next-line eqeqeq
        if (assetId != null) {
            this.selectedAsset = this.assets?.find(a => a.id === assetId);
        } else {
            this.selectedAsset = this.assets?.[0];
        }

        this.selectedType = selectedtype;
    }

    get currentItem() {
        return this.assets && this.assets[this.currentIndex];
      }

    closeGallery(): void {
        this.isOpen = false;
      }
    
      next(): void {
        if (this.assets && this.currentIndex < this.assets.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
      }
    
      prev(): void {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        } else {
          this.currentIndex = this.assets && this.assets.length - 1 || 0;
        }
      }
    
    openImage(assetId: string) {
        if (!this.assets) {
            return;
        }
        const index = this.assets.findIndex(a => a.id === assetId);
        // this.gallery.loadAndOpen(index);
        this.isOpen = true;
    }

    getFileType(filePath: string): string {
        const extension = filePath.split('.').pop()?.toLowerCase();
    
        if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
          return 'image';
        } else if (extension === 'mp4' || extension === 'webm' || extension === 'ogg') {
          return 'video';
        } else {
          return 'unknown';
        }
      }

}
