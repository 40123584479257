<div class="relative">
    <div 
        aria-hidden="true"
        class="absolute inset-0 overflow-hidden"
    >
        <!-- <img
            class="absolute inset-0 w-full object-contain h-full"
            [src]="heroImage + '?w=800&format=webp'"
            alt="header"
        /> -->

        <img
            class="absolute inset-0 w-full object-cover h-full"
            src="assets/cracker.jpg"
            alt="header"
        />
        <!--<div class="absolute inset-0 bg-gradient-to-br from-zinc-50 to-zinc-900 mix-blend-darken"></div>-->
    </div>
    <div id="container" class="hidden sm:absolute z-30 h-full w-full"></div>
    <div
        aria-hidden="true"
        class="absolute inset-0 bg-gray-800 opacity-70"
    ></div>
    <!-- <div class="relative max-w-3xl mx-auto py-16 px-6 flex flex-col items-center text-center sm:pt-72 sm:pb-72 lg:px-0 z-20">
        <div class="relative bg-zinc-800 bg-opacity-0 rounded-lg p-0">
            <h1 class="text-6xl text-transparent bg-clip-text font-extrabold tracking-normal lg:text-6xl bg-gradient-to-r from-white to-gray-200">
                <span class="text-[#aa2d2f] glow">ROHINI</span> CRACKERS
            </h1>
        </div>

        <div class="mt-4 text-2xl text-white">
            <p>We are dedicated to bringing joy and celebration to your special moments.<span class="whitespace-nowrap"> Celebrate with us and make every occasion unforgettable! </span></p>

            <p>
                <span class="text-blue-300 hover:text-blue-500">Explore</span>
                &
                <a href="http://localhost:4201/category/home-garden" class="text-red-700 hover:text-red-500">SHOP</a>
            </p>
        </div>
    </div> -->
    <div class="relative max-w-3xl mx-auto py-16 px-6 flex flex-col items-center text-center sm:pt-62 sm:pb-72 lg:px-0">
        <div class="relative bg-zinc-800 bg-opacity-0 rounded-lg p-0">
            <h1 class="text-6xl text-transparent bg-clip-text font-extrabold tracking-normal lg:text-6xl bg-gradient-to-r from-white to-gray-200">
                <img alt="Rohini Crackers" class="md:w-full" src="assets/main-logo-white.png">

                <!-- <span class="text-[#aa2d2f] glow">ROHINI</span> Crackers -->
            </h1>
        </div>

        <div class="mt-4 text-2xl text-white">
            <p>We are dedicated to bringing joy and celebration to your special moments.<span class="whitespace-nowrap"></span></p>
<br/>
            <p>
                <a href="/category/explore-products" class="text-blue-300 hover:text-blue-500 font-bold">Explore</a>
                &
                <a href="/category/explore-products" class="border-2 border-red-300 hover:px-4 px-2 py-1 rounded-lg bg-red-400 text-white font-bold transition-all hover:bg-red-500">SHOP</a>
            </p>
        </div>
    </div>
</div>
<!-- <div class="max-w-6xl mx-auto px-4 pt-12">
    <div class="box-content py-2 px-2 relative overflow-x-auto xl:overflow-visible">
        <div
            class="grid justify-items-center grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:gap-x-8">
            <vsf-collection-card *ngFor="let collection of collections$ | async"
                                 [collection]="collection">
            </vsf-collection-card>
        </div>
    </div>
</div> -->

<div class="relative overflow-hidden bg-gradient-to-r from-orange-100 to-yellow-100 p-4 rounded-xl shadow-lg" 
      (mouseenter)="pauseAutoScroll()" 
      (mouseleave)="resumeAutoScroll()">
  <div class="flex transition-transform duration-500 ease-in-out"
        [style.transform]="'translateX(-' + currentTranslation + '%)'">
    <div *ngFor="let slide of slides; let i = index" 
          class="w-full flex-shrink-0 p-4 text-center">
      <div class="bg-white bg-opacity-80 p-6 rounded-lg shadow-md h-full flex flex-col justify-center items-center">
        <h2 class="text-2xl font-bold mb-4 text-orange-600">{{ slide.title }}</h2>
        <p class="text-lg mb-4 text-gray-800" [innerHTML]="slide.content"></p>
        <div class="text-yellow-500 text-4xl">{{ slide.icon }}</div>
      </div>
    </div>
  </div>
  <button (click)="prev()" 
          class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-orange-500 text-white rounded-full p-2 focus:outline-none hover:bg-orange-600 transition-colors">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
    </svg>
  </button>
  <button (click)="next()"
          class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-orange-500 text-white rounded-full p-2 focus:outline-none hover:bg-orange-600 transition-colors">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
    </svg>
  </button>
</div>

<div class="bg-gray-50 min-h-screen">
  <div class="container mx-auto px-4 py-8 sm:px-6 lg:px-8">
    <h1 class="text-4xl sm:text-5xl font-bold text-center text-gray-800 mb-8 hidden-custom">About Us</h1>
    
    <p class="text-lg sm:text-xl text-gray-700 mb-8 text-center hidden-custom">
      Light up this special festival of Deepawali with <span class="font-bold text-indigo-600">Rohini Crackers</span>, your trusted partner for high-quality fireworks. We offer an extensive range of crackers, fancy fireworks, sparklers, gift boxes, and more, all available online with just a few clicks.
    </p>

    <div class="bg-white shadow-xl rounded-lg overflow-hidden mb-8 hidden-custom">
      <div class="p-6">
        <h2 class="text-3xl font-semibold text-gray-800 mb-4">Leading the Way in Sivakasi Crackers</h2>
        <p class="text-gray-700">
          Rohini Crackers is proud to be one of the leading online platforms for purchasing the finest Sivakasi crackers. Our commitment to quality and customer satisfaction sets us apart, making us the preferred choice for fireworks enthusiasts across the country.
        </p>
      </div>
    </div>

    <h2 class="text-3xl font-semibold text-gray-800 mb-6 text-center hidden-custom">Why Choose Rohini Crackers?</h2>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div *ngFor="let feature of features" class="bg-white shadow-lg rounded-lg overflow-hidden hidden-custom">
        <div class="p-6">
          <h3 class="text-xl font-semibold text-gray-800 mb-2">{{feature.title}}</h3>
          <p class="text-gray-700">{{feature.content}}</p>
        </div>
      </div>
    </div>

    <div class="mt-8 hidden-custom">
      <h2 class="text-3xl font-semibold text-gray-800 mb-4">Wholesale Opportunities</h2>
      <p class="text-gray-700">
        Rohini Crackers also offers the best wholesale crackers in Sivakasi. If you're looking to purchase in bulk, explore our wholesale price list and enjoy unmatched deals and discounts.
      </p>
    </div>

    <div class="mt-8 bg-red-100 border-l-4 border-red-500 p-4 rounded-lg hidden-custom">
      <p class="text-sm text-red-700 font-semibold mb-2">
        Important Notice:
      </p>
      <p class="text-sm text-red-700">
        As per the 2018 Supreme Court order, online sale of firecrackers is not permitted! We value our customers and, at the same time, respect jurisdiction. We request you to add your products to the cart and submit the required crackers through the enquiry button. We will contact you within 24 hours to confirm the order through WhatsApp or phone call. Please add and submit your enquiries and enjoy your Diwali with Rohini Crackers.
      </p>
    </div>

    <div class="mt-4 bg-gray-100 p-4 rounded-lg hidden-custom">
      <p class="text-sm text-gray-600">
        Our GST NO: <strong>33HIFPS0427B1ZM</strong> and License No. <strong>119/2023</strong>. Rohini Crackers, as a company, follows 100% legal & statutory compliances, and all our shops and go-downs are maintained as per the Explosives Act. We send parcels through registered and legal transport service providers, just like every other major company in Sivakasi.
      </p>
    </div>
  </div>
</div>

<!-- <div class="container mx-auto p-4 sm:p-6 lg:p-8">

    <h1 class="text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-6 sm:mb-8">About Us</h1>
    
    <p class="text-base sm:text-lg text-gray-700 mb-4 sm:mb-6">
      Light up this special festival of Deepawali with <strong>Rohini Crackers</strong>, your trusted partner for high-quality fireworks. We offer an extensive range of crackers, fancy fireworks, sparklers, gift boxes, and more, all available online with just a few clicks.
    </p>
  
    <h2 class="text-2xl sm:text-3xl font-semibold text-gray-800 mb-3 sm:mb-4">Leading the Way in Sivakasi Crackers</h2>
    <p class="text-base sm:text-lg text-gray-700 mb-4 sm:mb-6">
      Rohini Crackers is proud to be one of the leading online platforms for purchasing the finest Sivakasi crackers. Our commitment to quality and customer satisfaction sets us apart, making us the preferred choice for fireworks enthusiasts across the country.
    </p>
  
    <h2 class="text-2xl sm:text-3xl font-semibold text-gray-800 mb-3 sm:mb-4">Why Choose Rohini Crackers?</h2>
    
    <div class="mb-4 sm:mb-6">
      <h3 class="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">Supreme Quality Crackers</h3>
      <p class="text-base sm:text-lg text-gray-700">
        At Rohini Crackers, we prioritize quality above all. Our crackers are made from the finest raw materials, ensuring a superior and safe experience for all our customers.
      </p>
    </div>
  
    <div class="mb-4 sm:mb-6">
      <h3 class="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">Innovative & Attractive Designs</h3>
      <p class="text-base sm:text-lg text-gray-700">
        We offer an innovative range of crackers that are not only visually appealing but also come in secure and creative packaging. Whether you’re looking for traditional or modern designs, we have something for everyone.
      </p>
    </div>
  
    <div class="mb-4 sm:mb-6">
      <h3 class="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">Vibrant & Colourful Displays</h3>
      <p class="text-base sm:text-lg text-gray-700">
        Add an extra spark to your celebrations with our colourful range of crackers. Designed to produce vivid colors with minimal smoke, our crackers are perfect for a joyful and environmentally conscious festival.
      </p>
    </div>
  
    <div class="mb-4 sm:mb-6">
      <h3 class="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">Safety First</h3>
      <p class="text-base sm:text-lg text-gray-700">
        Your safety is our priority. Rohini Crackers provides a wide selection of 100% safe crackers, especially curated for children. Our products meet all safety standards, ensuring a worry-free celebration for your family.
      </p>
    </div>
  
    <div class="mb-4 sm:mb-6">
      <h3 class="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">Competitive Pricing</h3>
      <p class="text-base sm:text-lg text-gray-700">
        We believe in providing value for money. Rohini Crackers offers top-quality fireworks at genuine and affordable prices, making your celebrations not just memorable but also budget-friendly.
      </p>
    </div>
  
    <div class="mb-4 sm:mb-6">
      <h3 class="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">Customer Satisfaction Guaranteed</h3>
      <p class="text-base sm:text-lg text-gray-700">
        Our dedicated customer care team is here to ensure that your experience with us is nothing short of perfect. We stand by the quality of our products and guarantee full satisfaction with every purchase.
      </p>
    </div>
  
    <h2 class="text-2xl sm:text-3xl font-semibold text-gray-800 mb-3 sm:mb-4">Wholesale Opportunities</h2>
    <p class="text-base sm:text-lg text-gray-700">
      Rohini Crackers also offers the best wholesale crackers in Sivakasi. If you’re looking to purchase in bulk, explore our wholesale price list and enjoy unmatched deals and discounts.
    </p>
</div>
  
<div class="bg-gray-100 p-4 sm:p-6 mt-8 border-t border-gray-300">
    <p class="text-xs sm:text-sm text-gray-600">
      <strong>As per the 2018 Supreme Court order, online sale of firecrackers is not permitted!</strong> We value our customers and, at the same time, respect jurisdiction. We request you to add your products to the cart and submit the required crackers through the enquiry button. We will contact you within 24 hours to confirm the order through WhatsApp or phone call. Please add and submit your enquiries and enjoy your Diwali with Rohini Crackers.
    </p>
    <p class="text-xs sm:text-sm text-gray-600 mt-2">
      Our GST NO: <strong>33HIFPS0427B1ZM</strong> and License No. <strong>119/2023</strong>. Rohini Crackers, as a company, follows 100% legal & statutory compliances, and all our shops and go-downs are maintained as per the Explosives Act. We send parcels through registered and legal transport service providers, just like every other major company in Sivakasi.
    </p>
</div> -->