<vsf-layout>
    <vsf-layout-header>
        <div class="bg-gradient-to-r from-zinc-600 to-zinc-800 shadow-lg transform shadow-xl">
            <div class="bg-zinc-100 text-gray-600 shadow-inner text-center text-sm py-2 px-2 xl:px-0">
                <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-between">
                    <div>
                        <p class="flex items-center font-semibold">
                            <i class="fa fa-whatsapp" style="font-size:24px; color: green; margin-right: 5px;"></i> +91 8754202505
                        </p>
                    </div>
                    <div>
                        <p class="website-name hidden sm:flex">
                            <img alt="Rohini Crackers" class="md:w-full" src="assets/main-logo-1.png">
                        </p>
                    </div>
                    <div>
                        <vsf-account-link class="mr-4"></vsf-account-link>
                    </div>
                </div>
            </div>
            <div class="max-w-6xl mx-2 py-2 md:mx-auto flex items-center space-x-4">
                <vsf-mobile-menu-toggle class="md:hidden"></vsf-mobile-menu-toggle>
                <a [routerLink]="['/']" class="l">
                    <img alt="Rohini Crackers" class="w-10" src="assets/flowerpot.png">
                </a>
                <vsf-collections-menu class="hidden md:block text-white"></vsf-collections-menu>
                <vsf-product-search-bar class="flex-1"></vsf-product-search-bar>
                <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>
            </div>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile [visible]="mobileNavVisible$ | async" class="z-10"></vsf-collections-menu-mobile>
    <vsf-cart-drawer (close)="closeCartDrawer()" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer>
        <footer
            aria-labelledby="footer-heading"
            class="mt-24 border-t bg-gray-50"
        >
            <h2 class="sr-only" id="footer-heading">
                Footer
            </h2>
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
                <div class="xl:grid xl:grid-cols-2 xl:gap-8">
                    <div class="grid text-center md:grid-cols-2 gap-8 xl:col-span-2">
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Shop
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of topCollections$ | async">
                                        <a
                                            [routerLink]="['/category/explore-products', item.slug]"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-12 md:mt-0">
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Visit US
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <div class="text-base text-gray-500 hover:text-gray-600">
                                        <strong>ROHINI CRACKERS SHOP</strong> <br/>
                                        Virudhunagar main road, Behind bharath petroleum bulk, <br/>
                                        Anaikuttam, Sivakasi - 626130
                                    </div>
                                    <!-- <li *ngFor="let item of navigation.support">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li> -->
                                    <br/>
                                    <div>
                                        <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                            Contact US
                                        </h3>
                                        <ul class="mt-4 space-y-4" role="list">
                                            <li>
                                                <span class="text-base text-gray-500 hover:text-gray-600">
                                                    <strong>+91 8754202505</strong>
                                                </span>
                                            </li>
                                            <li>
                                                <span class="text-base text-gray-500 hover:text-gray-600">
                                                    <strong>+91 9500404822</strong>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div class="md:grid md:grid-cols-1 md:gap-8">
                            <div class="relative pb-9/16 overflow-hidden">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15740.015550569737!2d77.8352934!3d9.5083904!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cfd74d2c135f%3A0x67813a7281e8fec1!2sRohini%20Crackers%20Shop!5e0!3m2!1sen!2sin!4v1724591662972!5m2!1sen!2sin" 
                                class="w-full h-full" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <!-- <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Contact US
                                </h3>
                                <ul class="mt-4 space-y-4" role="list"> -->
                                   <!-- <li *ngFor="let item of navigation.company">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li> -->
                                <!-- </ul> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                    <!-- <div class="mt-8 xl:mt-0">
                        <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                            Subscribe to our newsletter
                        </h3>
                        <p class="mt-4 text-base text-gray-500">
                            Be the first to know about exclusive offers & deals.
                        </p>
                        <form class="mt-4 sm:flex sm:max-w-md">
                            <label class="sr-only" htmlFor="email-address">
                                Email address
                            </label>
                            <input
                                autoComplete="email"
                                class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                                id="email-address"
                                name="email-address"
                                placeholder="Enter your email"
                                required
                                type="email"
                            />
                            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    class="w-full bg-primary-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
                                    type="submit"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </form>
                    </div> -->
                </div>
            </div>
        </footer>
    </vsf-layout-footer>

</vsf-layout>
