import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

import { AddToCartMutation, AddToCartMutationVariables, GetProductDetailQuery, GetProductDetailQueryVariables, SearchProductsQuery } from '../../../common/generated-types';
import { ADD_TO_CART, GET_PRODUCT_DETAIL } from '../product-detail/product-detail.graphql';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';
import { NotificationService } from '../../providers/notification/notification.service';
import { ActiveService } from '../../providers/active/active.service';

type Variant = NonNullable<GetProductDetailQuery['product']>['variants'][number];

@Component({
    selector: 'vsf-product-card',
    templateUrl: './product-card.component.html',
    // styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnInit {

    @Input() product: SearchProductsQuery['search']['items'][number];
    @Output() prodAsset = new EventEmitter<string>();

    inFlight = false;
    qtyInCart: { [id: string]: number; } = {};
    private sub: any;
    qty = 1;
    gotproduct: GetProductDetailQuery['product'];
    prodAssetDoc: any;
    selectedVariant: any;
    @ViewChild('addedToCartTemplate', {static: true})
    private addToCartTemplate: TemplateRef<any>;
    featuredAsset: any;

    constructor(private dataService: DataService,
        private stateService: StateService,
        private notificationService: NotificationService,
        private activeService: ActiveService,
        private changeRef: ChangeDetectorRef,
        // private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        console.log(this.product);
        this.activeService.activeOrder$.subscribe(order => {
            this.qtyInCart = {};
            for (const line of order?.lines ?? []) {    
                this.qtyInCart[line.productVariant.name] = line.quantity;
            }
            this.changeRef.detectChanges();
        });
    }

    fetchProductVariant(slug: string) {
        this.sub =  this.dataService.query<GetProductDetailQuery, GetProductDetailQueryVariables>(GET_PRODUCT_DETAIL, {
                        slug: slug,
                    },
                )
        .subscribe(({product}: any) => {
            console.log(product);
            this.gotproduct = product;
            this.selectedVariant = this.gotproduct?.variants[0];
            this.featuredAsset = this.gotproduct;

            this.addToCart(this.selectedVariant, this.qty);
            this.changeRef.detectChanges();

        });
    }

    addToCart(variant: Variant, qty: number) {

        this.inFlight = true;
        this.dataService.mutate<AddToCartMutation, AddToCartMutationVariables>(ADD_TO_CART, {
            variantId: variant.id,
            qty,
        }).subscribe(({addItemToOrder}) => {
            this.inFlight = false;
            switch (addItemToOrder.__typename) {
                case 'Order':
                    this.stateService.setState('activeOrderId', addItemToOrder ? addItemToOrder.id : null);
                    console.log(variant);
                    if (this.featuredAsset) {
                        console.log(this.featuredAsset);
                        this.notificationService.notify({
                            title: 'Added to cart',
                            type: 'info',
                            duration: 3000,
                            templateRef: this.addToCartTemplate,
                            templateContext: {
                                variant: this.featuredAsset,
                                quantity: qty,
                            },
                        }).subscribe();
                    }
                    break;
                case 'OrderModificationError':
                case 'OrderLimitError':
                case 'NegativeQuantityError':
                case 'InsufficientStockError':
                    this.notificationService.error(addItemToOrder.message).subscribe();
                    break;
            }

        });
    }

    fetchAssets(slug: string) {
        this.sub =  this.dataService.query<GetProductDetailQuery, GetProductDetailQueryVariables>(GET_PRODUCT_DETAIL, {
                        slug: slug,
                    },
                )
        .subscribe(({product}: any) => {
            this.prodAssetDoc = product.assets;
            this.prodAsset.emit(this.prodAssetDoc);
        });
    }

    openModal(slug: string) {
        this.fetchAssets(slug);
    }

    viewCartFromNotification(closeFn: () => void) {
        this.stateService.setState('cartDrawerOpen', true);
        closeFn();
    }
}
