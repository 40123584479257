<div class="bg-gradient-to-br from-purple-100 to-indigo-100 min-h-screen font-sans">
    <div class="container mx-auto px-4 py-8">
        <div class="bg-white rounded-lg shadow-xl p-4 sm:p-6 md:p-10">
            <h1 class="text-3xl sm:text-4xl font-bold text-center mb-6 sm:mb-8 text-indigo-800">Payment Details</h1>
            
            <div class="mb-8 sm:mb-10">
                <h2 class="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4 text-indigo-700">Terms & Conditions</h2>
                <ol class="list-decimal pl-5 sm:pl-6 space-y-2 text-sm sm:text-base text-gray-700">
                    <li>Minimum order quantity is Rs.2000/- within Tamilnadu & Rs.5000/- for other states.</li>
                    <li>Transportation charges to be paid by customer.</li>
                    <li>100% Payment to be paid in advance.</li>
                    <li>No discount for gift boxes, customized gift boxes available.</li>
                    <li>Price list is valid up to 2024 DIWALI.</li>
                </ol>
            </div>
            
            <div class="mb-8 sm:mb-10">
                <h2 class="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-indigo-700">Payment Methods</h2>
                <div class="grid sm:grid-cols-2 gap-4 sm:gap-6">
                    <div class="bg-indigo-50 p-4 sm:p-6 rounded-lg shadow">
                        <h3 class="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-indigo-600">Bank Transfer - Option 1</h3>
                        <p class="text-sm sm:text-base"><span class="font-semibold">Name:</span> Boomadevi B</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">A/C Number:</span> 37576786107</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">IFSC CODE:</span> SBIN0012767</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">Bank:</span> STATE BANK OF INDIA</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">Branch:</span> THIRUTHANGAL BRANCH, SIVAKASI</p>
                    </div>
                    <div class="bg-indigo-50 p-4 sm:p-6 rounded-lg shadow">
                        <h3 class="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-indigo-600">Bank Transfer - Option 2</h3>
                        <p class="text-sm sm:text-base"><span class="font-semibold">Name:</span> Govarthanan R</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">A/C Number:</span> 31511873486</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">IFSC CODE:</span> SBIN0009664</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">Bank:</span> STATE BANK OF INDIA</p>
                        <p class="text-sm sm:text-base"><span class="font-semibold">Branch:</span> SIVAKASI TOWN BRANCH</p>
                    </div>
                </div>
                <div class="mt-4 sm:mt-6 bg-indigo-50 p-4 sm:p-6 rounded-lg shadow">
                    <h3 class="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-indigo-600">Digital Payments</h3>
                    <p class="text-sm sm:text-base"><span class="font-semibold">G-pay, Phone pe, Paytm:</span> +918754202505</p>
                    <p class="text-sm sm:text-base"><span class="font-semibold">G-pay:</span> +919500404822</p>
                </div>
            </div>
            
            <div class="flex flex-col sm:flex-row justify-around items-center space-y-6 sm:space-y-0">
                <div class="text-center">
                    <h3 class="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-indigo-600">QR Code 1</h3>
                    <img src="assets/qrcode.jpeg" alt="QR Code 1" class="mx-auto rounded-lg shadow-md w-48 h-48 sm:w-[16rem] sm:h-[16rem]">
                </div>
                <div class="text-center">
                    <h3 class="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-indigo-600">QR Code 2</h3>
                    <img src="assets/qrcode1.jpeg" alt="QR Code 2" class="mx-auto rounded-lg shadow-md w-48 h-48 sm:w-[16rem] sm:h-[16rem]">
                </div>
            </div>
        </div>
    </div>
</div>