<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<ng-template [ngIf]="product">
    <div class="flex flex-col">
        <!-- <img (click)="openModal(product.slug)"
            class="pointer rounded-xl flex-grow object-cover aspect-[7/8]"
            alt=""
            [src]="product.productAsset | assetPreview:300:400"
        /> -->
        <img (click)="openModal(product.slug)"
            class="pointer rounded-xl w-full object-cover aspect-[7/8]"
            alt="{{ product.name }}"
            [src]="product.productAsset | assetPreview:300:400"
        />
        <div class="h-2"></div>
        <!-- <a class="text-sm text-gray-700"  prefetch='intent' [routerLink]="['/product', product.slug]">
            {{ product.productName }}
        </a> -->
        <div class="text-md text-gray-700">
            {{ product.productName }}
        </div>
        <div class="text-md font-medium text-gray-900">
            <ng-template [ngIf]="product.priceWithTax.min === product.priceWithTax.max" [ngIfElse]="range">
                {{ product.priceWithTax.min | formatPrice }}
            </ng-template>
            <ng-template #range>
                From {{ product.priceWithTax.min | formatPrice }}
            </ng-template>
        </div>
    </div>
    <button
        type="submit"
        class="max-w-xs flex-1 transition-colors border border-transparent rounded-md py-2 md:py-3 px-6 md:px-8 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 w-full"
        [ngClass]="{
            'bg-gray-400': inFlight,
            'bg-primary-600 hover:bg-primary-700': !inFlight
        }"
        (click)="fetchProductVariant(product.slug)"
    >
        <div *ngIf="!qtyInCart[product.productName]; else inCartCount">Add to cart</div>
        <ng-template #inCartCount>
            <fa-icon icon="check" class="mr-2"></fa-icon>
            <span>{{ qtyInCart[product.productName] }} in cart</span>
        </ng-template>
    </button>
</ng-template>

<ng-template #addedToCartTemplate let-variant="variant" let-quantity="quantity" let-close="closeFn">
    <div class="flex">
        <div class="mr-8">
            <img
                class="rounded w-14 h-14"
                [src]="(variant.featuredAsset?.preview || gotproduct?.featuredAsset?.preview) + '?preset=tiny'"
                alt="product thumbnail"
            />
        </div>
        <div class="text-sm">{{ quantity }} x {{ variant.name }}</div>
    </div>
    <div class="flex justify-end">
        <button type="button"
                (click)="viewCartFromNotification(close)"
                class="inline-flex items-center rounded border border-transparent bg-primary-100 px-2.5 py-1.5 text-xs font-medium text-primary-700 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            View cart
        </button>
    </div>
</ng-template>